var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "all_detail"
  }, [_c('div', {
    staticClass: "detail"
  }, [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "person_infor_left"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_vm._m(0), _c('div', {
    staticClass: "login_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('contactUs')))])])])]), _c('div', {
    staticClass: "all_lists"
  }, [_c('div', {
    staticClass: "list_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick(1);
      }
    }
  }, [_vm._v(" WhatsApp：" + _vm._s(_vm.WhatsApp) + " ")]), _c('div', {
    staticClass: "list_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick(2);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('WechatNumber')) + "："), _c('span', {
    attrs: {
      "id": "copy"
    }
  }, [_vm._v(_vm._s(_vm.weChat))])]), _c('span', {
    staticClass: "copy_text",
    attrs: {
      "data-clipboard-target": "#copy"
    },
    on: {
      "click": _vm.copyClick
    }
  }, [_vm._v(_vm._s(_vm.$t('copy')))])]), _c('div', {
    staticClass: "list_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick(3);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('techniqueFeedback')) + "：" + _vm._s(_vm.feedback) + " ")]), _c('div', {
    staticClass: "list_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick(4);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('business')) + "：" + _vm._s(_vm.business) + " ")]), _c('div', {
    staticClass: "list_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick(5);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('complain')) + "：" + _vm._s(_vm.complain) + " ")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]);
}];
export { render, staticRenderFns };