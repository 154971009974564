export default {
  data() {
    return {
      feedback: 'soporte@publichino.com',
      //技术反馈
      business: 'ventas@publichino.com',
      //商业
      WhatsApp: '507 64594186',
      weChat: 'PTY5566',
      //微信号
      complain: 'sugerencia@publichino.com' //投诉
    };
  },

  methods: {
    itemClick(val) {
      switch (val) {
        case 1:
          let whatsApp = '+' + this.WhatsApp;
          let whatsAppContactUrl = 'https://api.whatsapp.com/send?phone=' + whatsApp;
          window.open(whatsAppContactUrl);
          break;
        case 2:
          break;
        case 3:
          window.location.href = "mailto:" + this.feedback;
          break;
        case 4:
          window.location.href = "mailto:" + this.business;
          break;
        case 5:
          window.location.href = "mailto:" + this.complain;
          break;
      }
    },
    // 复制微信号
    copyClick() {
      var clipboard = new this.Clipboard('.copy_text');
      clipboard.on('success', e => {
        this.$message.success(this.$t('copySuccess'));
        // 释放内存  
        clipboard.destroy();
      });
    }
  }
};